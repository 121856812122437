<template>
  <div class="layout">
    <!-- 顶部导航栏 -->
    <Header ref="Header" />
    <!-- 动态内容 -->
    <main>
      <router-view />
    </main>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import { throttle } from "@/utils/common";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    this.handleScroll = throttle(this.handleScroll, 200);
    return {
      navbar: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.$nextTick(() => {
      this.navbar = document.getElementById("header");
    });
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 50 && !this.navbar.classList.contains("scrolled")) {
        this.navbar.classList.add("scrolled");
      } else if (
        scrollPosition <= 50 &&
        this.navbar.classList.contains("scrolled")
      ) {
        this.navbar.classList.remove("scrolled");
      }
    },
  },
  breforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="less" scoped>
.layout {
  position: relative;
  min-width: 1024px;
}
main {
  min-height: calc(100vh - 404px);
}
</style>
