export const routePath = {
  Home: "/home",
  Partnership: "/partnership",
  AboutUs: "/about-us",
  Contact: "/contact",
  ADAS: "/product-solutions/automotive/adas",
  InCabin: "/product-solutions/automotive/in-cabin",
  APA: "/product-solutions/automotive/apa",
  CommercialVehicles: "/product-solutions/automotive/commercial-vehicles",
  TurboFusion: "/product-solutions/smartphone/turbo-fusion",
  AigcSolutions: "/product-solutions/smartphone/aigc",
};
