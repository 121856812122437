<template>
  <div class="404">
    <div class="banner">
      <ImageWithPlaceholder src="/image/404/404.jpg" alt="Loaded Image" />
    </div>
    <div class="404-container">
      <div class="content-wrapper">
        <div class="not-find-content">
          <h2>Hmm, we can't seem to find that page.</h2>
          <p>
            That page has either moved or no longer exists. Sorry about that.
            Here are some quick links that might help you get back on track:
          </p>
          <div class="page-to-container">
            <router-link
              :to="getRoutePath(item.key)"
              v-for="item in pageToList"
              :key="item.key"
            >
              <div class="page-to-item">
                <div class="item-bg">
                  <img :src="item.imgSrc" alt="" />
                </div>
                <div class="item-title">{{ item.name }}</div>
                <div class="item-icon">
                  <img src="image/404/white-arrow.svg" alt="" />
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder.vue";
import { routePath } from "@/router/routePath.js";
export default {
  name: "404",
  metaInfo: {
    title: "404",
    meta: [
      {
        name: "description",
        content: "description",
      },
      {
        name: "keywords",
        content: "404", // 关键词
      },
    ],
  },
  components: { ImageWithPlaceholder },
  data() {
    return {
      pageToList: [
        {
          name: "Product Solutions",
          key: "InCabin",
          imgSrc: "/image/404/4041.jpg",
        },
        {
          name: "Partnership",
          key: "Partnership",
          imgSrc: "/image/404/4042.jpg",
        },
        {
          name: "About us",
          key: "AboutUs",
          imgSrc: "/image/404/4043.jpg",
        },
        {
          name: "Contact",
          key: "Contact",
          imgSrc: "/image/404/4044.jpg",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    getRoutePath(key) {
      return routePath[key] || "";
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  img {
    width: 100%;
  }
}

h2 {
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  margin-bottom: 24px;
}

.not-find-content {
  padding: 80px 0;
  p {
    text-align: center;
    margin-bottom: 40px;
  }
}

.page-to-container {
  display: flex;
  .page-to-item {
    box-sizing: border-box;
    border: solid 1px #d9d9d9;
    width: 340px;
    height: 200px;
    padding: 16px 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .item-bg {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      overflow: hidden;
      img {
        display: block;
      }
    }
    .item-title {
      color: #fafafa;
      font-size: 20px;
    }
  }
}

.content-wrapper {
  width: 1358px;
  margin: 0 auto;
}
</style>
