<template>
  <div>
    <transition name="popup-fade">
      <div v-if="visible" class="popup-overlay"></div>
    </transition>
    <transition name="popup-move">
      <div
        v-show="visible"
        :class="{ showPopup: visible }"
        class="popup-content"
      >
        <div class="popup-header" v-if="title">
          <h3>{{ title }}</h3>
          <!-- <button @click="close">X</button> -->
        </div>
        <div class="popup-body">
          <slot>
            <div class="icon">
              <img :src="iconImg" alt="" />
            </div>
            <span class="text">{{ content }}</span>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false, // 控制弹窗的显示和隐藏
      title: "",
      content: "",
      type: undefined,
      delay: null, // 关闭延迟时间
    };
  },
  computed: {
    iconImg() {
      return this.type === "success"
        ? "/image/contact/checj.svg"
        : "/image/contact/fail.svg";
    },
  },
  methods: {
    open(options) {
      const { title, content, type, delay } = options;
      this.title = title;
      this.content = content;
      this.type = type;
      // console.log(this.type);
      document.body.style.overflow = "hidden"; // 禁止滚动
      this.visible = true;
      if (delay && typeof delay === "number") {
        setTimeout(this.close, delay); // 延迟关闭
      }
    },
    close() {
      this.visible = false;
      document.body.style.overflow = ""; // 恢复滚动
    },
  },
};
</script>
<style lang="less" scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  //   pointer-events: none;
}

.popup-content {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  text-align: center;
  color: #222222;
  font-size: 16px;
  width: 320px;
  height: 104px;
  box-sizing: border-box;
  transform: scale(0.5); /* 初始缩放为0.5 */
  transition: transform 0.3s ease; /* 添加动画效果 */
}

.showPopup {
  transform: scale(1); /* 展开时设置缩放为1 */
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h3 {
  margin: 0;
}

.popup-body {
  font-size: 16px;
  color: #222222;
  font-weight: bold;
  .icon {
    padding: 5px 0;

    img {
      width: 24px;
      height: 24px;
    }
  }
  .text {
    padding-top: 10px;
  }
}

.popup-fade-enter-active,
.popup-fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.popup-fade-enter,
.popup-fade-leave-to {
  opacity: 0;
  //   transform: scale(0.9);
}

.popup-move-enter-active,
.popup-move-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.popup-move-enter,
.popup-move-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
