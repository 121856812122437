<template>
  <div class="image-wrapper">
    <!-- 占位符，当图片未加载时显示 -->
    <div v-if="!isLoaded" class="placeholder">
      <slot name="placeholder">
        <!-- 默认的占位符，可以是图片或背景 -->
        <!-- <div class="default-placeholder">Loading...</div> -->
      </slot>
    </div>

    <!-- 实际图片，当图片加载完成后显示 -->
    <transition name="fade">
      <img
        v-show="isLoaded"
        v-bind="$attrs"
        @load="handleImageLoad"
        class="actual-image"
        :class="['actual-image', { 'image-loaded': isLoaded }]"
      />
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    placeholderSrc: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoaded: false, // 控制是否加载完成
    };
  },
  methods: {
    handleImageLoad() {
      this.isLoaded = true; // 图片加载完成后，显示图片
    },
  },
  mounted() {
    // 检测图片是否已缓存，处理已经加载的情况
    const img = new Image();
    img.src = this.$attrs.src;
    if (img.complete) {
      this.isLoaded = true;
    }
  },
};
</script>

<style lang="less" scoped>
.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  img {
    display: block;
  }
}

.placeholder {
  background-color: rgba(0, 0, 0, 0.9);

  width: 100%;
  min-height: 300px;
  //  width: 100vw; /* 占满视口的宽度 */
  // height: calc(100vw * (700 / 1920));
  aspect-ratio: 1920 / 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.default-placeholder {
  /* 默认占位符的样式，可以是文字或者加载动画 */
  font-size: 16px;
  color: #aaa;
}

.actual-image {
  /* 实际图片样式 */
  width: 100%;
  height: 100%;
  object-fit: cover; /* 确保图片适应容器 */
  opacity: 0;
  transition: opacity 0.5s ease; /* 使用透明度进行过渡 */
}

.image-loaded {
  opacity: 1; /* 当图片加载完成时，显示出来 */
}

/* 过渡进入的效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear, filter 0.4s linear;
}

/* 过渡开始和结束的效果 */
.fade-enter,
.fade-leave-to .fade-leave {
  opacity: 0;
  filter: blur(10px); /* 你可以调整模糊程度 */
}
</style>
