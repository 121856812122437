<template>
  <header id="header">
    <div class="top_wrapper">
      <div class="logo">
        <router-link to="/">
          <img src="/image/logo.png" alt="arcsoft" />
        </router-link>
      </div>
      <nav class="r-nav">
        <ul class="nav-list">
          <li class="nav-item" v-for="(item, item_i) in navList" :key="item_i">
            <router-link class="nav-title" :to="getRoutePath(item.key)">{{
              item.name
            }}</router-link>
            <div class="nav-sub-item" v-show="!hidden" v-if="item.children">
              <ul v-for="(sub, sub_i) in item.children" :key="sub_i">
                <li class="sub-title">
                  <router-link :to="getRoutePath(sub.key)">{{
                    sub.name
                  }}</router-link>
                </li>
                <li
                  class="sub-item"
                  v-for="(subItem, subItem_i) in sub.children"
                  :key="subItem_i"
                >
                  <router-link
                    @click.native="setHidden()"
                    :to="getRoutePath(subItem.key)"
                    >{{ subItem.name }}</router-link
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import { routePath } from "@/router/routePath.js";
export default {
  name: "Header",
  data() {
    return {
      navList: [
        {
          name: "Product Solutions",
          toPath: "",
          key: "ProductSolutions",
          children: [
            {
              name: "Automotive Visual Solutions",
              toPath: "",
              key: "AutomotiveVisualSolutions",
              children: [
                {
                  name: "In-cabin Visual Software Solutions",
                  toPath: "",
                  key: "InCabin",
                },
                {
                  name: "Auto-Parking Assist (APA) Solutions on a Single SoC",
                  toPath: "",
                  key: "APA",
                },
                {
                  name: "Integrated ADAS Solutions",
                  toPath: "",
                  key: "ADAS",
                },
                {
                  name: "Integrated Solutions for Commercial Vehicles",
                  toPath: "",
                  key: "CommercialVehicles",
                },
              ],
            },
            {
              name: "Computational Photography Solutions for Smartphone",
              toPath: "",
              key: "SmartphoneVisualSolutions",
              children: [
                {
                  name: "Turbo Fusion™ Products for Smartphone",
                  toPath: "",
                  key: "TurboFusion",
                },
                {
                  name: "AIGC Large Model Solutions for Smartphone",
                  toPath: "",
                  key: "AigcSolutions",
                },
              ],
            },
          ],
        },
        {
          name: "Partnership",
          toPath: "",
          key: "Partnership",
        },
        {
          name: "About us",
          toPath: "",
          key: "AboutUs",
        },
        {
          name: "Contact",
          toPath: "",
          key: "Contact",
        },
      ],
      hidden: false,
      timeoutId: null,
    };
  },
  mounted() {
    // 更新 navList 中的路径
    // this.updateToPath(this.navList, routePath);
  },
  methods: {
    getRoutePath(key) {
      return routePath[key] || "";
    },
    setHidden() {
      if (this.hidden) return;

      this.hidden = true;
      // console.log(this.hidden);
      this.timeoutId = setTimeout(() => {
        this.hidden = false;
      }, 200);
    },
    // // 函数：递归更新 navList 中的 toPath
    // updateToPath(navList, routePath) {
    //   navList.forEach((item) => {
    //     // 使用 key 作为匹配字段来更新 toPath
    //     if (routePath[item.key]) {
    //       item.toPath = routePath[item.key];
    //     }

    //     // 如果存在 children，则递归调用该函数
    //     if (item.children && item.children.length > 0) {
    //       this.updateToPath(item.children, routePath);
    //     }
    //   });
    // },
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); // 清理定时器
    }
  },
};
</script>
<style lang="less" scoped>
.scrolled {
  background-color: rgba(0, 0, 0, 0.5); /* 黑色背景，透明度50% */
}
header {
  z-index: 99;
  height: 80px;
  // background-image: linear-gradient(180deg, rgba(0, 0, 0, 40%), #00000000 100%);
  background-color: transparent;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  transition: background 0.5s ease;
  .logo {
    float: left;
    height: 100%;
    img {
      display: block;
      height: 100%;
    }
  }

  .r-nav {
    float: right;
    padding: 0 16px;
    .nav-list {
      color: #ffffff;
      display: flex;
      line-height: 80px;
      .nav-item {
        margin-right: 40px;
        position: relative;
        .nav-title {
          font-size: 20px;
        }
        &::before {
          content: "";
          display: block;
          height: 2px;
          width: 100%;
          background-color: #e83021;
          position: absolute;
          left: 0;
          bottom: 20px;
          transform: scaleX(0); /* 初始宽度为0，缩放X轴 */
          transform-origin: center; /* 以中心为原点 */
          transition: transform 0.3s ease; /* 平滑的过渡效果 */
        }
        &:hover {
          &::before {
            transform: scaleX(1);
          }
          .nav-sub-item {
            display: block;
          }
        }
        .nav-sub-item {
          display: none;
          position: absolute;
          left: 0;
          top: 80px;
          border: 1px red solid;
          line-height: 24px;
          padding: 24px;
          background-color: rgba(255, 255, 255, 0.7);
          backdrop-filter: blur(30px);
          -webkit-backdrop-filter: blur(30px);
          border: 1px solid rgba(255, 255, 255, 0.18);
          box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
          -webkit-box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
          border-radius: 0px;
          -webkit-border-radius: 0px;
          white-space: nowrap;
          text-align: left;
          font-size: 14px;
          .sub-title {
            color: #222;
            font-weight: bold;
            padding-left: 10px;
            position: relative;
            a {
              line-height: 32px;
            }
            &::after {
              content: "";
              display: block;
              height: 24px;
              width: 2px;
              background-color: #e83021;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50% + 4px);
            }
          }
          .sub-item {
            color: #555555;
            padding-left: 20px;
            a {
              line-height: 32px;
            }
          }
        }
      }
      .nav-item:last-child {
        margin-right: 0;
      }
    }
  }

  .top_wrapper {
    max-width: 1440px;
    margin: 0 auto;
    height: 100%;
  }
}

// 小于一定尺寸时隐藏菜单
@media (max-width: 960px) {
  .r-nav {
    display: none;
  }
}
</style>
