import Vue from "vue";
import PopupComponent from "@/components/Popup.vue";

const PopupConstructor = Vue.extend(PopupComponent);

const popupPlugin = {
  install(Vue) {
    const popupInstance = new PopupConstructor();
    popupInstance.$mount(document.createElement("div"));
    document.body.appendChild(popupInstance.$el);

    Vue.prototype.$popup = {
      open(options) {
        popupInstance.open(options);
      },
      close() {
        popupInstance.close();
      },
    };
  },
};

export default popupPlugin;
