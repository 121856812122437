import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/reset.css";
import "./styles/common.css";
import "./styles/media.css";

Vue.config.productionTip = false;

// SEO优化
import MetaInfo from "vue-meta-info";
Vue.use(MetaInfo);

import popupPlugin from "@/utils/popupPlugin";
Vue.use(popupPlugin);

// h1标签
import h1Title from "./components/h1-title.vue";
Vue.component("h1Title", h1Title);

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
